import React, { useEffect } from "react";
import "./App.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
// import { Link } from "react-scroll";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin-icon.svg";
import { Tooltip } from "react-tooltip";
import drillingrigImage from "./assets/drillingrig.png";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";  


function App() {

  useEffect(() => {
    // Initialize Google Analytics 4
    ReactGA.initialize("G-N5KNWSDRNM");
    // Track a pageview event
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>DrillGPT - Next-gen Tool for Drilling</title>
        <meta
          name="description"
          content="DrillGPT is the cutting-edge AI and ML tool optimized for petroleum and drilling tasks. Discover the innovative features of DrillGPT, and revolutionize your drilling processes."
        />
        <meta
          name="keywords"
          content="chatgpt for drilling, chatgpt for oil, chatgpt oil and gas, petroleum, petro, drilling, drill, rig, workover, AI, ML, chat, chatGPT, GPT, exploration, oil, gas, drilling engineering, drill"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
      </Helmet>

      <header>
        <h1>DrillGPT</h1>
      </header>

      <main>
        <div className="first-screen-wrapper">
          <div className="content-wrapper">
            <div className="logo-placeholder">
              <img
                src={drillingrigImage}
                alt="drilling rig"
                className="responsive-image-servicesImage"
              />
            </div>
          </div>


              <section className="more-info-section" id="more-info-section">
                <h2>
                  <strong>Join the Future of Drilling with Drill-GPT!</strong>
                </h2>
                <h3>
                  <strong>Discover a Smarter Way to Solve Challenges</strong>
                </h3>
                <div className="about-content">
                  <p className="about-description">
                    Welcome to the future of drilling engineering support! Our
                    upcoming application powered by Gen AI is tailored
                    exclusively for professionals like you.
                  </p>
                  <h4>Sign up now to be the first to experience:</h4>
                  <div className="about-features">
                    <ul className="about-bulletpoints">
                      <li>
                        <strong>Instant Expertise:</strong> Get real-time
                        answers to your drilling queries, 24/7.
                      </li>
                      <li>
                        <strong>       Efficient Solutions:</strong> Solve problems
                        faster with AI-powered insights.
                      </li>
                      <li>
                        <strong>Stay Ahead:</strong> Receive industry news and
                        updates directly in the app.
                      </li>
                    </ul>
                  </div>
                  <p className="signup-invitation">
                    Join us now to shape the future of drilling –{" "}
                    <a href="#signup-section">sign up</a> for exclusive launch
                    updates!
                  </p>
                </div>
              </section>


          {/* <div className="scroll-indicator">
            <p className="hint-text">Find out more</p>
            <Link
              to="more-info-section"
              smooth={true}
              duration={800}
              className="scroll-arrow"
            >
              ⌄
            </Link>
          </div> */}
        </div>

        <section
          className="more-info-section-below"
          id="more-info-section-below"
        >
          <div className="signup-content">
            <p>Welcome to DrillGPT! The Next-gen Tool for Drilling.</p>
            <section className="signup-section" id="signup-section">
              <h2>Sign Up</h2>
              <SubscriptionForm />
            </section>
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-columns">
          {/* Skipped for brevity */}

          <div className="footer-column">
            <h4>Quick Links</h4>
            <a
              href="mailto:info@drill-gpt.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
          <div className="footer-column">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/genaifordrilling/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                data-tip="Follow us on LinkedIn"
              >
                <LinkedInIcon alt="LinkedIn" />
              </a>
              <Tooltip place="bottom" type="dark" effect="solid" />
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <span>
            © {new Date().getFullYear()} DrillGPT. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}


function SubscriptionForm() {
  const [isSubscribed, setIsSubscribed] = React.useState(false);

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
        try {
          const response = await axios.post(
            "https://drillgptserverside.vercel.app/api/subscribe",
            values
          );
          console.log(response);
          if (response.data === "Subscription successful") {
            // Track successful subscription event with Google Analytics
            ReactGA.event({
              category: "Form",
              action: "Subscription Successful",
              label: "User Subscribed"
            });

            setStatus({ success: true });
            resetForm();
            setIsSubscribed(true);
          } else {
            setStatus({ success: false, errorMessage: "Subscription failed." });
          }
        } catch (error) {
          console.error("Error occurred:", error);
          let errorMessage = "An error occurred. Please try again later.";
          if (error && error.response) {
            console.error(error.response);
          }
          setStatus({ success: false, errorMessage });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isValid, isSubmitting }) =>
        !isSubscribed ? (
          <Form>
            <label htmlFor="name">Name*:</label>
            <Field name="name" type="text" />
            <ErrorMessage name="name" component="div" className="error" />

            <label htmlFor="email">Email*:</label>
            <Field name="email" type="email" />
            <ErrorMessage name="email" component="div" className="error" />

            <button type="submit" disabled={!isValid || isSubmitting}>
              {isSubmitting ? "Subscribing..." : "Subscribe"}
            </button>
            {isSubmitting && <div className="spinner"></div>}
          </Form>
        ) : (
          <div className="subscription-success">
            <h3>Thank you!</h3>
            <p>You have successfully signed up for DrillGPT!</p>
          </div>
        )
      }
    </Formik>
  );
}

export default App;
